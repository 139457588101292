import { Link } from "react-router-dom";

export default function HomePage() {
  return (
    <div className="bg-black w-full ">
      <div className="container flex flex-col justify-center items-center h-screen">
        <h1 className="text-8xl">Oops! Page not found.</h1>
        <section className="flex space-x-12">
          <Link className="font-loRes underline" to={"/home-vr"}>
            Home VR
          </Link>
        </section>
      </div>
    </div>
  );
}
