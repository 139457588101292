import big from "../../assets/images/big-placeholder.png";

export default function SectionThree() {
  return (
    <section className="relative text-white overflow-hidden">
      <img
        src={big}
        alt=""
        className="transition-transform duration-200 ease-in-out hover:scale-150 w-full lgMax:aspect-square"
      />
      <div className="absolute top-14 inset-x-14">
        <p className="text-sm lg:text-xl font-loRes">Unique in every way</p>
        <p className="lg:text-3xl lg:w-11/12">
          Compelling story, uncommon enemy types, unexpected gameplay mechanics
        </p>
      </div>
    </section>
  );
}
