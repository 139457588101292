import { Link } from "react-router-dom";

export default function HomePage() {
  return (
    <div className="bg-black w-full h-screen flex flex-col justify-center items-center">
      <h1 className="text-8xl">Gazuntype</h1>
      <section className="flex space-x-12">
        <Link className="font-loRes underline" to={"https://gazuntype.com/"}>
          https://gazuntype.com
        </Link>
        <Link className="font-loRes underline" to={"/home-vr"}>
          Home VR
        </Link>
      </section>
    </div>
  );
}
