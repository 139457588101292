import { useEffect, useState } from "react";
import AllRoutes from "./routes";
import { CircularProgress } from "@mui/material";

export function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <CircularProgress className="text-4xl text-orange" />{" "}
      </div>
    );
  }
  return <AllRoutes />;
}
