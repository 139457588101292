import imageThree from "../../assets/images/placeholder-1.png";
import imageTwo from "../../assets/images/placeholder-2.png";
import imageOne from "../../assets/images/big-placeholder.png";
import bgLines from "../../assets/images/bg-lines.png";
import steamIcon from "../../assets/icons/steam-vr.svg";
import playIcon from "../../assets/icons/play.svg";
import lineIcon from "../../assets/icons/line.svg";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

export default function HeroSection() {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <section className="lg:h-[88vh] container px-20 py-40 lg:py-8 flex flex-col justify-center items-center relative overflow-hidden">
      <img src={bgLines} className="absolute top-0" alt="" />
      <h1
        style={{
          textShadow: "1.98074px 3.96149px 24.7593px rgba(0, 29, 116, 0.15)",
        }}
        className="font-bold text-[5rem] lg:text-9xl"
      >
        HOME VR
      </h1>
      <p className="lg:text-xl">Survival is the only aim</p>
      <Link
        to={"https://store.steampowered.com/app/1888690/Home_VR/"}
        className="relative mt-8 pl-4 pr-14 lg:px-8 py-4 bg-white text-black rounded-[2.75rem] font-bold lg:text-xl flex items-center"
      >
        <span>View on</span>
        <img className="mx-1" src={steamIcon} alt="steam icon" />
        <span>Steam</span>
        <img
          className="ml-5 z-10 lgMax:absolute right-5"
          src={playIcon}
          alt=""
        />
        <img
          className="absolute right-11 lgMax:right-8"
          src={lineIcon}
          alt=""
        />
      </Link>
      {/* 
      <div ref={ref}>
        <img
          src={imageOne}
          loading="lazy"
          alt=""
          className={`absolute left-12  w-[33rem] rounded-sm transition-transform duration-100 ease-in ${
            inView ? "-rotate-12 -bottom-4" : " -rotate-1 bottom-0"
          }`}
        />
        <img
          src={imageTwo}
          alt=""
          className={`absolute left-96 w-[33rem] rounded-sm transition-transform duration-100 ease-in ${
            inView ? "rotate-6 -bottom-96" : "-bottom-96"
          }`}
        />
        <img
          src={imageThree}
          alt=""
          className={`absolute right-16 w-[33rem] rounded-sm transition-transform duration-100 ease-in ${
            inView ? "-bottom-88 rotate-45" : "rotate-12 -bottom-96"
          }`}
        />
      </div> */}
    </section>
  );
}
