import { Link } from "react-router-dom";
import { socialLinks } from "./Header";

export default function Footer() {
  return (
    <footer className="bg-orange pt-64 pb-4">
      <div className="container h-full flex flex-col justify-center items-center">
        <p className="uppercase font-semibold text-[2.5rem] font-integralCF">
          Say Hello
        </p>
        <Link
          to="mailto:hi@gazuntype.com"
          className="font-medium font-generalSans text-3xl pt-6 pb-14"
        >
          hi@gazuntype.com
        </Link>
        <div className="flex items-center divide-x divide-[#FFFFFF33]">
          {socialLinks.map(({ icon, route }) => (
            <Link className="px-11" to={route} key={route}>
              {icon}
            </Link>
          ))}
        </div>
        <p className="pt-60 opacity-50 text-sm">© Tade Ajiboye 2023</p>
      </div>
    </footer>
  );
}
