import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomeVRPage } from "../components/home-vr";
import HomePage from "../components/home";
import NotFound from "../components/common/NotFound";

export default function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<HomePage />} />
        <Route path="/home-vr" element={<HomeVRPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
