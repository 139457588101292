import imageOne from "../../assets/images/placeholder-1.png";
import imageTwo from "../../assets/images/placeholder-2.png";

const sectionTwoDetails = [
  {
    image: imageOne,
    title: "Home VR",
    description: (
      <>
        <span className="font-bold">Stealth</span> at its peak.
      </>
    ),
  },
  {
    image: imageTwo,
    title: "Built specifically for VR",
    description: (
      <>
        Home fully <span className="font-bold">immerses</span> you in the
        struggles of an unsuspecting little girl.{" "}
      </>
    ),
  },
];

export default function SectionTwo() {
  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 text-white">
      {sectionTwoDetails.map(({ image, title, description }) => (
        <div key={title} className="relative overflow-hidden">
          <img
            src={image}
            alt=""
            className="transition-transform duration-200 ease-in-out hover:scale-150 w-full"
          />
          <div className="absolute top-14 inset-x-14">
            <p className="text-sm lg:text-xl font-loRes">{title}</p>
            <p className="lg:text-3xl">{description}</p>
          </div>
        </div>
      ))}
    </section>
  );
}
