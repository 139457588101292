export default function YoutubeSection() {
  return (
    <section className="container flex justify-center py-32">
      <iframe
        width="862"
        height="485"
        src="https://www.youtube.com/embed/XO2RXRnmX-k"
        title="Shooter Arena Trailer!"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </section>
  );
}
