import { Close, GitHub, LinkedIn, Mail, Menu } from "@mui/icons-material";
import { useState } from "react";
import { Link } from "react-router-dom";

const navLinks = [
  {
    text: "work",
    route: "/work",
  },
  {
    text: "home VR",
    route: "/home-vr",
  },
  {
    text: "contact me",
    route: "/contact-me",
  },
];

export const socialLinks = [
  {
    icon: <LinkedIn />,
    route: "https://www.linkedin.com/in/tade-ajiboye-gazuntype",
  },
  {
    icon: <Mail />,
    route: "mailto:hi@gazuntype.com",
  },
  {
    icon: <GitHub />,
    route: "https://github.com/Gazuntype",
  },
];

export default function Header() {
  return (
    <header
      style={{
        background: "rgba(9, 0, 12, 0.1)",
        backdropFilter: "blur(15px)",
      }}
      className="sticky top-0 z-30 py-5 font-generalSans font-medium"
    >
      <div className="container flex items-center justify-between">
        <Link to="/" className="text-[1.3125rem] lg:text-[1.6875rem]">
          gazuntype<span className="text-blue">.</span>
        </Link>
        <div className="flex space-x-14 items-center lgMax:hidden">
          <div className="flex space-x-6 items-center">
            {navLinks.map(({ text, route }) => (
              <Link to={route} key={route}>
                <span
                  className={
                    window.location.pathname === route
                      ? "text-orange mr-[0.375rem]"
                      : "hidden"
                  }
                >
                  &#8226;
                </span>
                <span
                  className={
                    window.location.pathname === route ? "font-semibold" : ""
                  }
                >
                  {text}
                </span>
              </Link>
            ))}
          </div>
          <div className="flex space-x-5 items-center">
            {socialLinks.map(({ icon, route }) => (
              <Link to={route} key={route}>
                {icon}
              </Link>
            ))}
          </div>
        </div>
        <HamburgerMenu />
      </div>
    </header>
  );
}

function HamburgerMenu() {
  const [menuVisible, setMenuVisible] = useState(false);
  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  return (
    <div className="lg:hidden relative">
      <button className="h-6 w-6" onClick={openMenu}>
        <Menu />
        <span className="sr-only">open menu</span>
      </button>

      <div className={`menu z-50 bg-black ${menuVisible ? "visible" : ""}`}>
        <div className="container flex flex-col items-stretch text-left space-y-7">
          <div className="flex items-end py-7 w-full justify-between">
            <p className="text-[1.3125rem]">
              gazuntype<span className="text-blue">.</span>
            </p>
            <button
              className="lg:hidden flex items-center justify-center"
              onClick={closeMenu}
            >
              <Close />
              <span className="sr-only">close menu</span>
            </button>
          </div>

          {navLinks.map(({ text, route }) => (
            <Link to={route} key={route}>
              <span
                className={
                  window.location.pathname === route
                    ? "text-orange mr-[0.375rem]"
                    : "hidden"
                }
              >
                &#8226;
              </span>
              <span
                className={
                  window.location.pathname === route ? "font-semibold" : ""
                }
              >
                {text}
              </span>
            </Link>
          ))}
        </div>
      </div>

      <style>
        {`
        .menu {
          width: 100vw;
          height: 100vh;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: fixed;
          top: 0;
          right: 0;
          opacity: 0;
          transform: scale(0);
          transform-origin: top;
        }

        .menu.visible {
          opacity: 1;
          transform: scale(1);
        }
      `}
      </style>
    </div>
  );
}
