import SectionThree from "./SectionThree";
import HeroSection from "./HeroSection";
import SectionTwo from "./SectionTwo";
import YoutubeSection from "./YoutubeSection";
import Header from "../common/Header";
import Footer from "../common/Footer";

export function HomeVRPage() {
  return (
    <>
      <Header />
      <HeroSection />
      <SectionTwo />
      <SectionThree />
      <YoutubeSection />
      <Footer />
    </>
  );
}
